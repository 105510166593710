import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import pic from '../images/about-us.jpg';

class AboutUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isArticleVisible: true,
      timeout: false,
      articleTimeout: false,
      article: 'about-us'
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.timerID1 = setTimeout(() => this.timeout(), 325);
    this.timerID2 = setTimeout(() => this.articleTimeout(), 350);
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  timeout() {
    this.setState({
      timeout: !this.state.timeout
    });
  }

  articleTimeout() {
    this.setState({
      articleTimeout: !this.state.articleTimeout
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <div id="wrapper" className="reduceSize">
            <article
              id="about-us"
              className={`${'active'} ${
                this.props.articleTimeout ? 'timeout' : ''
              }`}
            >
              <h2 className="major">About Us</h2>
              <span className="image main" style={{ marginBottom: 48 }}>
                <img src={pic} alt="about us" />
              </span>
              <p>
                HYTech is a next generation, high-tech consulting company that
                delivers solutions to help our partners and customers grow and
                succeed. We specialize in building and implementing software
                solutions in managed services for customers looking to digitally
                transform their business, whether they are at the beginning or
                further along their digital transformation journey.
              </p>
              <p>
                Led by executives with a combined 30+ years of experience in
                tech and many Fortune 500 companies such as Gannett, Concur, SAP
                and Capital One. Our company is based around serving the needs
                of our clients. Each of us cares about doing what's best for our
                clients. We define our success based on your success.
              </p>
              <p>
                We've seen it over and over again: a client went with the wrong
                software development company and got a product built so wrong it
                needs to be rewritten (by us). The most expensive mistake is
                having to engineer your product more than once because it didn't
                get done right the first time. Our development process puts in
                the initial work to ensure that what you're getting is exactly
                what you need.
              </p>
            </article>

            <Footer />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    );
  }
}

export default AboutUsPage;
